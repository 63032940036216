import { Button, Collapse, Dropdown, Form, Input, Menu, Modal, Spin } from 'antd';
import { CLEAR_CURRENT_USER, SET_DEFAULT_CURRENCY, SET_LOGIN_STATUS, SET_REFERRAL_MODAL_SHOW, useAuth } from '../../../../hooks/auth/AuthContext';
import '../GeneralHeader.less';
import { FormattedMessage, useIntl } from 'react-intl';
import LangThai from '../../../../assets/images/language-thailand.png';
import LangEn from '../../../../assets/images/language-uk.png';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { ANON_FORGOT_PASSWORD_REQUEST_CODE, ANON_FORGOT_PASSWORD_SUBMIT, LOGOUT, SET_PASSWORD, SIGN_IN } from '../../../../pages/auth/auth.gql';
import { encode } from 'base-64';
import { clearAllTokens, setTokenCookie } from '../../../helpers/set-token-cookie.helper';
import { useEffect, useState } from 'react';
import useNotification from '../../../../hooks/layout/useNotification';
import useModal from '../../../../hooks/layout/useModal';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import useSpinner from '../../../../hooks/layout/useSpinner';
import { AiOutlineMail, AiOutlineMinus, AiOutlinePlus, AiOutlinePlusCircle } from 'react-icons/ai';
import { transferWalletList } from '../../../../pages/dashboard/dashboard-bottom/components/transfer/TransferWalletLists';
import NumberFormat from 'react-number-format';
import { SET_CURRENCY, SET_LANGUAGE, useLayout } from '../../../../hooks/layout/LayoutContext';
import userNameIcon from '../../../../assets/images/icon-form-username.png';
import phoneIcon from '../../../../assets/images/icon-form-contact.png';
import passwordIcon from '../../../../assets/images/icon-form-password.png';
import PhoneInputFloatingLabel from '../../phone-input/Phone-Input-floating-label';
import { setGeneralCookie } from '../../../helpers/set-general-cookie.helper';
import { VerificationType, formatUrlCurrencyLang } from '../../../helpers/general-helpers';
import { useCookies } from 'react-cookie';
import CodeRequestButton from '../../Button/CodeRequestButton';
import HeaderWalletDropdown from './HeaderWalletDropdown';
import HeaderLanguageDropdown from './HeaderLanguageDropdown';

import CountryThai from '../../../../assets/images/language-thailand.png';
import CountrySg from '../../../../assets/images/language-singapore.png';

const { Panel } = Collapse;

const HeaderRightFirstRow = () => {
    const { layoutState, layoutDispatch } = useLayout();
    const { setLoading } = useSpinner();
    const { authState, authDispatch } = useAuth();
    const [form] = Form.useForm();
    const [otpForm] = Form.useForm();
    const [forgotPasswordForm] = Form.useForm();
    const intl = useIntl();
    const navigate = useNavigate();
    const { setSuccessNotification, setErrorNotification } = useNotification();
    const { setErrorModal } = useModal();
    const [signIn, { data, loading, error: signInError }] = useMutation(SIGN_IN, {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
    });
    const [logout, { data: logoutData, loading: logoutLoading }] = useMutation(LOGOUT, { fetchPolicy: "no-cache", errorPolicy: "all" });
    const [langIcon, setLangIcon] = useState(LangThai);
    const [forgotPasswordModalVisible, setForgotPasswordModalVisible] = useState(false);
    const [userNameFocus, setUserNameFocus] = useState(false);
    const [phoneFocus, setPhoneFocus] = useState(false);
    const [otpFocus, setOtpFocus] = useState(false);
    const [passwordFocus, setPasswordFocus] = useState(false);
    const [confirmPasswordFocus, setConfirmPasswordFocus] = useState(false);
    const [userNameInput, setUserNameInput] = useState("");
    const [otpInput, setOtpInput] = useState("");
    const [passwordInput, setPasswordInput] = useState("");
    const [confirmPasswordInput, setConfirmPasswordInput] = useState("");
    const [requestOTPCode, { data: requestOTPCodeData, loading: requestOTPCodeLoading, error: requestOTPCodeError }] = useMutation(ANON_FORGOT_PASSWORD_REQUEST_CODE, {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
    });
    const [submitOTPCode, { data: submitOTPData, error: submitOTPCodeError, loading: submitOTPLoading }] = useMutation(ANON_FORGOT_PASSWORD_SUBMIT,
        { errorPolicy: 'all' });
    const [submitNewPassword, { data: setPasswordData, loading: setPasswordLoading }] = useMutation(SET_PASSWORD,
        { errorPolicy: 'all' });
    const [requestedCode, setRequestedCode] = useState(false);
    const [cookies] = useCookies();
    const [countdownTime, setCountdownTime] = useState<number>(179);
    const [otpValidationError, setOtpValidationError] = useState(false);
    const [showPasswordPage, setShowPasswordPage] = useState(false);
    const [isRequestCode, setIsRequestCode] = useState(false);

    useEffect(() => {
        let language = localStorage.getItem('pfxl');
        if (!language) {
            language = layoutState.locale;
        }
    }, [])

    useEffect(() => {
        if (authState.defaultCurrency) {
            setRegionFlag(authState.defaultCurrency);
        }
    }, [authState.defaultCurrency]);

    useEffect(() => {
        if (loading || setPasswordLoading || submitOTPLoading) {
            setLoading(true);
        } else {
            setLoading(false);
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, setPasswordLoading, submitOTPLoading])

    useEffect(() => {
        const cookiesCountdownTimer = cookies[`c-cooldown-start-${VerificationType.PHONE}`];

        if (cookiesCountdownTimer) {
            setCountdownTime(+cookiesCountdownTimer);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestOTPCodeLoading]);

    useEffect(() => {
        if (data && data.signIn) {
            const { a, r, sid } = data.signIn;
            setTokenCookie(process.env.REACT_APP_ACCESS_TOKEN || "gmf-at", a);
            setTokenCookie(process.env.REACT_APP_REFRESH_TOKEN || "gmf-rt", r);
            setTokenCookie("gmf-sid", sid);
            authDispatch({ type: SET_LOGIN_STATUS, payload: true });
            authDispatch({ type: SET_REFERRAL_MODAL_SHOW, payload: true });
            navigate('dashboard');
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (submitOTPCodeError) {
            setErrorNotification(intl.formatMessage({ id: "RESET_PASSWORD_FAILED" }));
        } else if (submitOTPData && requestedCode) {
            if (submitOTPData.anonForgotPasswordSubmit.status === "SUCCESS") {

                setGeneralCookie(`c-cooldown-start-${VerificationType.PHONE}`, '59', 59);
                setShowPasswordPage(true);
            } else if (submitOTPData.anonForgotPasswordSubmit.status === "CODE_NOT_MATCH") {
                setErrorNotification(intl.formatMessage({ id: "INVALID_OTP" }));
            } else {
                setErrorNotification(intl.formatMessage({ id: "RESET_PASSWORD_FAILED" }));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitOTPData, submitOTPCodeError, requestedCode]);

    useEffect(() => {
        if (requestOTPCodeData) {
            if (requestOTPCodeData.anonForgotPasswordRequestCode.status === "SUCCESS") {
                const cookiesCountdownTimer = cookies[`c-cooldown-start-${VerificationType.PHONE}`];

                if (cookiesCountdownTimer) {
                    setCountdownTime(+cookiesCountdownTimer);
                }

                setIsRequestCode(true);
            } else if (requestOTPCodeData.anonForgotPasswordRequestCode.status === "DATA_NOT_EXISTED") {
                setErrorNotification(intl.formatMessage({ id: "USERNAME_OR_PHONE_NUMBER_NOT_EXISTED" }));
            } else {
                setErrorNotification(intl.formatMessage({ id: "RESET_PASSWORD_FAILED" }));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestOTPCodeData]);

    useEffect(() => {
        if (setPasswordData && setPasswordData.setNewPassword) {
            if (setPasswordData.setNewPassword.status === 'SUCCESS') {
                setSuccessNotification(intl.formatMessage({ id: 'RESET_PASSWORD_SUCCESS' }));
                setForgotPasswordModalVisible(false);
                navigate(`/`);
            } else {
                setErrorNotification(intl.formatMessage({ id: `RESET_PASSWORD_FAILED` }));
            }
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setPasswordData]);

    const onSubmitOTP = () => {
        submitOTPCode({
            variables: {
                userName: otpForm.getFieldValue('username') ?? '',
                verificationType: VerificationType.PHONE,
                phone: otpForm.getFieldValue('phone') ?? '',
                verificationCode: otpForm.getFieldValue('otp') ?? ''
            },
        });
    }

    const onSubmitNewPassword = (fieldsValue: any) => {
        submitNewPassword({
            variables: {
                userName: otpForm.getFieldValue('username') ?? '',
                phone: otpForm.getFieldValue('phone') ?? '',
                password: encode(fieldsValue.password) ?? ''
            }
        })
    }

    const onFinish = (values: any) => {

        const fields = form.getFieldsValue();
        if (fields.username === undefined || fields.username === "") {
            setErrorModal(intl.formatMessage({ id: 'LOGIN_USERNAME_REQUIRED' }));
            return;
        } else if (fields.password === undefined || fields.password === "") {
            setErrorModal(intl.formatMessage({ id: 'LOGIN_PASSWORD_REQUIRED' }));
            return;
        }

        signIn({
            variables: {
                userName: values.username,
                password: encode(values.password)
            },
        });

    };

    const onRegClick = () => {
        navigate('register');
    }

    const onLogoutClick = () => {
        logout();
    }

    useEffect(() => {
        if (signInError) {
            if (signInError.message?.indexOf("INVALID_LOGIN") > -1 || signInError.message?.indexOf("INVALID_PHONE_LOGIN") > -1) {
                setErrorNotification(intl.formatMessage({ id: `AUTH_ERROR_INVALID_LOGIN` }));
            } else if (signInError.message?.indexOf("ACCOUNT_LOCKED") > -1) {
                setErrorNotification(intl.formatMessage({ id: `AUTH_ERROR_ACCOUNT_LOCKED` }));
            }
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signInError]);

    useEffect(() => {
        if (logoutData && logoutData.logout) {
            clearAllTokens();
            authDispatch({ type: CLEAR_CURRENT_USER });
            authDispatch({ type: SET_LOGIN_STATUS, payload: false });
            navigate(`/`);
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logoutData])


    const onInboxClick = () => {
        navigate('dashboard/5');
    }

    const onUserNameCLick = () => {
        navigate('dashboard');
    }

    const onLanguageClick = (language: string, currency: string) => {
        localStorage.setItem('pfxl', language);
        localStorage.setItem('pfxc', currency);
        layoutDispatch({ type: SET_LANGUAGE, payload: language })
        layoutDispatch({ type: SET_CURRENCY, payload: currency });
        authDispatch({ type: SET_DEFAULT_CURRENCY, payload: currency });

        setRegionFlag(currency);
    }

    const onForgotPasswordClick = () => {
        setForgotPasswordModalVisible(true);
    }

    const handleCancel = () => {
        setForgotPasswordModalVisible(false);
    }

    const requestStatus = () => {
        let userName = otpForm.getFieldValue('username');
        let phone = otpForm.getFieldValue('phone');

        if (!userName || !phone) {
            setErrorNotification(intl.formatMessage({ id: 'OTP_VERIFICATION_ERROR' }));
            setOtpValidationError(true);
        } else {
            setOtpValidationError(false);
            setRequestedCode(true);
            requestOTPCode({
                variables: {
                    userName: otpForm.getFieldValue('username') ?? '',
                    verificationType: VerificationType.PHONE,
                    phone: otpForm.getFieldValue('phone') ?? ''
                },
            });
        }
    };

    const setRegionFlag = (currency: string) => {
        if (currency.toUpperCase() === 'SGD') {
            setLangIcon(CountrySg);
            localStorage.setItem('pfxc', currency);
        } else {
            setLangIcon(CountryThai)
            localStorage.setItem('pfxc', 'THB');
        }
    }

    const userNameLabelClass = userNameFocus || (userNameInput && userNameInput.length !== 0) ? "label label-float" : "label";
    const userNameInputClass = userNameFocus || (userNameInput && userNameInput.length !== 0) ? "input-underline" : "";

    const otpLabelClass = otpFocus || (otpInput && otpInput.length !== 0) ? "label label-float" : "label";
    const otpInputClass = otpFocus || (otpInput && otpInput.length !== 0) ? "input-underline" : "";

    const passwordLabelClass = passwordFocus || (passwordInput && passwordInput.length !== 0) ? "label label-float" : "label";
    const passwordInputClass = passwordFocus || (passwordInput && passwordInput.length !== 0) ? "input-underline" : "";

    const confirmPasswordLabelClass = confirmPasswordFocus || (confirmPasswordInput && confirmPasswordInput.length !== 0) ? "label label-float" : "label";
    const confirmPasswordInputClass = confirmPasswordFocus || (confirmPasswordInput && confirmPasswordInput.length !== 0) ? "input-underline" : "";

    return (
        <>
            <Form
                name="basic"
                form={form}
                autoComplete="off"
                initialValues={{ isSMSLogin: '0' }}
                onFinish={onFinish}>
                <div className='header-right-first-row'>
                    {authState?.isLogin ?
                        <div className='login-header-elements'>
                            <div className="user-container" onClick={onUserNameCLick}>
                                <div className='login-user-icon'>
                                    <UserOutlined />
                                </div>
                                <div className='login-username'>
                                    {
                                        authState.userProfile?.userName ?? ''
                                    }
                                </div>
                            </div>
                            <div className='login-inbox-btn' onClick={onInboxClick}>
                                <AiOutlineMail />
                            </div>
                            <Dropdown disabled overlay={<HeaderWalletDropdown />} className="dashboard-transfer-wallet-dropdown">
                                <div className='login-wallet-btn'>
                                    {
                                        authState?.wallets && authState?.wallets?.length > 0 ?
                                            <div className='login-wallet-btn-amt'>{authState.userProfile?.currency} <NumberFormat displayType="text" value={authState?.totalBalance.toFixed(2)} thousandSeparator={true} /></div> :
                                            <Spin />
                                    }

                                    {/* <AiOutlinePlusCircle /> */}
                                </div>
                            </Dropdown>
                            <Button className="logout-btn" type="default" htmlType="button" onClick={onLogoutClick} loading={logoutLoading}>
                                <FormattedMessage id='LOGOUT_BTN' />
                            </Button>
                        </div> :
                        <>
                            <div className='login-elements-container'>
                                <div className='login-elements-top-container'>
                                    <div className="header-forgot-password" onClick={onForgotPasswordClick}><FormattedMessage id='LOGIN_FORGOT_PASSWORD' /></div>
                                </div>
                                <div className='login-elements-btm-container'>
                                    <Form.Item
                                        label=""
                                        name="username"
                                    // rules={[
                                    //     { required: true, message: intl.formatMessage({ id: 'LOGIN_USERNAME_REQUIRED' }) },
                                    //     { min: 5, message: intl.formatMessage({ id: 'AUTH_INVALID_CODE' }) }]}
                                    >
                                        <Input type={"text"} placeholder={intl.formatMessage({ id: 'LOGIN_USERNAME_PLACEHOLDER' })} className='auth-input-verification' />
                                    </Form.Item>
                                    <Form.Item
                                        label=""
                                        name="password"
                                    // rules={[{ required: true, message: <FormattedMessage id="LOGIN_PASSWORD_REQUIRED" /> }]}
                                    >
                                        <Input.Password className='auth-input-password' placeholder={intl.formatMessage({ id: 'LOGIN_PASSWORD_PLACEHOLDER' })} />
                                    </Form.Item>
                                    <div className="login-register-wrapper">
                                        <Form.Item>
                                            <Button className="login-btn" type="primary" htmlType="submit" loading={loading}>
                                                <FormattedMessage id={'LOGIN_LOGIN_BTN'} />
                                            </Button>
                                        </Form.Item>
                                        <Button className="join-btn" type="default" htmlType="button" onClick={onRegClick}>
                                            <FormattedMessage id='LOGIN_JOIN_BTN' />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </>}

                    <Dropdown overlay={<HeaderLanguageDropdown containerName={'language-dropdown'} onLanguageClick={onLanguageClick} />} className="header-language-dropdown" trigger={['hover']}>
                        <div className={authState?.isLogin ? 'header-language-login-container' : 'header-language-container'}>
                            <img className="header-language-img" src={langIcon} alt="language" />
                            <DownOutlined />
                        </div>
                    </Dropdown>
                    {/* <div className="header-language-wrapper">
                        <div className="header-language" ><img className="header-language-img" src={langIcon} alt="language" /></div>
                        <div className='header-language-options'>
                            <div className={thClassName} onClick={() => onLanguageClick('th')}><FormattedMessage id='LANGUAGE_TH' /></div>
                            <div className={enClassName} onClick={() => onLanguageClick('en')}><FormattedMessage id='LANGUAGE_EN' /></div>
                        </div>
                    </div> */}
                </div>
            </Form>
            <Modal
                className="forgot-password-modal"
                visible={forgotPasswordModalVisible}
                closable={true}
                footer={null}
                onCancel={handleCancel}
                destroyOnClose>
                <div className='forgot-password-modal-container'>
                    <div className='forgot-password-modal-content-container'>
                        <div className='forgot-password-modal-title'>
                            <FormattedMessage id='LOGIN_FORGOT_PASSWORD' />
                        </div>
                        <div className='forgot-password-modal-content'>
                            {showPasswordPage ?
                                <Form
                                    name="basic"
                                    form={forgotPasswordForm}
                                    onFinish={onSubmitNewPassword}
                                    autoComplete="off">
                                    <div className="forgot-password-form-item">
                                        <img src={passwordIcon} alt="forgot password" className="form-password-icon" />
                                        <Form.Item
                                            name="password"
                                            rules={[
                                                { required: true, message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_PASSWORD_REQUIRED' }) },
                                                {
                                                    pattern: /[A-Za-z0-9]{6,19}$/,
                                                    message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_PASSWORD_INVALID' })
                                                }]}>
                                            <div
                                                className="float-label"
                                                onBlur={() => setPasswordFocus(false)}
                                                onFocus={() => setPasswordFocus(true)}
                                            >
                                                <Input.Password maxLength={20} className="input" value={passwordInput} onChange={e => setPasswordInput(e.target.value)} autoComplete="new-password" />
                                                <label className={passwordLabelClass}>{intl.formatMessage({ id: 'AUTH_REGISTER_FORM_PASSWORD' })}</label>
                                                <div className={passwordInputClass}></div>
                                            </div>
                                        </Form.Item>
                                    </div>
                                    <div className="forgot-password-form-item">
                                        <img src={passwordIcon} alt="password" className="form-password-icon" />
                                        <Form.Item
                                            name="confirmpassword"
                                            dependencies={['password']}
                                            rules={[{ required: true, message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_PASSWORD_REQUIRED' }) },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('password') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error(intl.formatMessage({ id: 'AUTH_REGISTER_FORM_PASSWORD_NOT_MATCHED' })));
                                                },
                                            })]}>
                                            <div
                                                className="float-label"
                                                onBlur={() => setConfirmPasswordFocus(false)}
                                                onFocus={() => setConfirmPasswordFocus(true)}
                                            >
                                                <Input.Password className="input" value={confirmPasswordInput} onChange={e => setConfirmPasswordInput(e.target.value)} />
                                                <label className={confirmPasswordLabelClass}>{intl.formatMessage({ id: 'AUTH_REGISTER_FORM_CONFIRM_PASSWORD' })}</label>
                                                <div className={confirmPasswordInputClass}></div>
                                            </div>
                                        </Form.Item>
                                    </div>
                                    <Form.Item className="form-button">
                                        <div className='form-mid-button'>
                                            <Button block type="primary" htmlType="submit" className="forgot-password-submit-btn" loading={loading}>
                                                <FormattedMessage id={"FORM_SUBMIT_BTN"} />
                                            </Button>
                                        </div>
                                    </Form.Item>
                                </Form>
                                :
                                <Form
                                    name="basic"
                                    form={otpForm}
                                    autoComplete="off">
                                    <div className="forgot-password-form-item">
                                        <img src={userNameIcon} alt="username" className="form-username-icon" />
                                        <Form.Item
                                            name="username"
                                            rules={[
                                                { required: true, message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_USERNAME_REQUIRED' }) },
                                                {
                                                    pattern: /^[A-Za-z][A-Za-z0-9]{3,14}$/,
                                                    message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_USERNAME_INVALID' })
                                                }]}>
                                            <div
                                                className="float-label"
                                                onBlur={() => setUserNameFocus(false)}
                                                onFocus={() => setUserNameFocus(true)}
                                            >
                                                <Input maxLength={15} value={userNameInput} className="input" onChange={e => setUserNameInput(e.target.value)} />
                                                <label className={userNameLabelClass}>{intl.formatMessage({ id: 'AUTH_REGISTER_FORM_USERNAME' })}</label>
                                                <div className={userNameInputClass}></div>
                                            </div>
                                        </Form.Item>
                                    </div>
                                    <div className="forgot-password-form-item">
                                        <img src={phoneIcon} alt="phone" className="form-password-icon" />
                                        <Form.Item
                                            name="phone"
                                            rules={[
                                                { required: true, message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_PHONE_REQUIRED' }) },
                                                { min: 3, message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_PHONE_REQUIRED' }) },
                                                { min: 8, message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_PHONE_INVALID' }) },
                                                { max: 12, message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_PHONE_INVALID' }) }]}>
                                            <PhoneInputFloatingLabel phoneFocus={phoneFocus} setPhoneFocus={setPhoneFocus} />
                                        </Form.Item>
                                    </div>
                                    <div className="forgot-password-form-item">
                                        <img src={passwordIcon} alt="password" className="form-username-icon" />
                                        <Form.Item
                                            name="otp"
                                            rules={[
                                                { required: true, message: intl.formatMessage({ id: 'FORGOT_PASSWORD_OTP_REQUIRED' }) },
                                                {
                                                    max: 6, message: intl.formatMessage({ id: 'FORGOT_PASSWORD_OTP_REQUIRED' })
                                                },
                                            ]}>
                                            <div
                                                className="float-label"
                                                onBlur={() => setOtpFocus(false)}
                                                onFocus={() => setOtpFocus(true)}
                                            >
                                                <Input maxLength={6} value={otpInput} className="input otp-input" onChange={e => setOtpInput(e.target.value)} />
                                                <label className={otpLabelClass}>{intl.formatMessage({ id: 'FORGOT_PASSWORD_OTP' })}</label>
                                                <div className={otpInputClass}></div>
                                            </div>
                                        </Form.Item>
                                        <CodeRequestButton
                                            type={VerificationType.PHONE}
                                            hasError={otpValidationError ? true : requestOTPCodeError ? true : false}
                                            requestStatus={requestStatus}
                                            isPinMatched={true}
                                            requestOTPCodeLoading={requestOTPCodeLoading}
                                            isRequestCode={isRequestCode}
                                            setIsRequestCode={setIsRequestCode}
                                        />
                                        {/* <div className='otp-request-btn' onClick={requestStatus}><FormattedMessage id={'FORGOT_PASSWORD_OTP_REQUEST'} /></div> */}
                                    </div>
                                    <div className='forgot-password-next-btn' onClick={onSubmitOTP}><FormattedMessage id={'FORM_NEXT_BTN'} /></div>
                                </Form>
                            }
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default HeaderRightFirstRow;