

import "../HomeMobile.less";
import { FormattedMessage } from "react-intl";
import { Tabs } from "antd";
import { useAuth } from "../../../../hooks/auth/AuthContext";

const { TabPane } = Tabs;

interface Props {
    selectedSubCat: any;
    defaultSubKey: any;
    onProviderChange: any;
    allGamesProvider: any
}

const MobileGameListTabHeader = ({ selectedSubCat, defaultSubKey, onProviderChange, allGamesProvider }: Props) => {
    const { authState } = useAuth();

    return (
        <Tabs animated tabPosition={'top'} defaultActiveKey={defaultSubKey} activeKey={selectedSubCat} onChange={onProviderChange}>
            {allGamesProvider &&
                allGamesProvider.map((games: any) => {
                    return (
                        ((authState?.isLogin && games?.currency.filter((x: any) => x === authState.userProfile?.currency).length > 0) ||
                            (!authState.isLogin && games?.currency.filter((x: any) => x === authState?.defaultCurrency).length > 0)) &&
                        <TabPane tab={
                            <div className="mobile-mid-menu-label">
                                <img className="mobile-mid-menu-logo" src={games.gameType === selectedSubCat ? games.mobileMenuImgSelected : games.mobileMenuImg} alt={games.gameType} />
                                <div className="mobile-mid-menu-txt">
                                    <FormattedMessage id={games.label} />
                                </div>
                            </div>
                        } key={games.gameType}>
                        </TabPane>
                    );
                })

            }
        </Tabs>
    );
};

export default MobileGameListTabHeader;
