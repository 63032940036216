

import "./HomeMobile.less";
import { FormattedMessage, useIntl } from "react-intl";
import { useEffect, useState } from "react";
import { gamesProviderLeft, gamesProviderRight } from "../../../shared/components/top-menu/TopMenuList";
import { useAuth } from "../../../hooks/auth/AuthContext";
import { useMutation } from "@apollo/client";
import { GET_GAME_URL } from "../../provider-game-page/Game.gql";
import useSpinner from "../../../hooks/layout/useSpinner";
import { useNavigate } from "react-router-dom";
import HeaderLoginMobile from "../../../shared/_mobile/header/HeaderLoginMobile";
import { Tabs } from "antd";
import MobileTransferModal from "../../../shared/_mobile/modals/MobileTransferModal";
import useNotification from "../../../hooks/layout/useNotification";
import { setTokenCookie } from "../../../shared/helpers/set-token-cookie.helper";
import MobileGameListRow from "./mobile-game-lists-components/MobileGameListRow";
import MobileGameListIcons from "./mobile-game-lists-components/MobileGameListIcons";
import MobileGameListTabHeader from "./mobile-game-lists-components/MobileGameListTabHeader";
import { checkIsIOSLineApp, checkLocation } from "../../../shared/helpers/general-helpers";
import { useLayout } from "../../../hooks/layout/LayoutContext";
import VpnDialog from "../../../shared/components/vpn-dialog/VpnDialog";

const { TabPane } = Tabs;

interface Props {
    selectedSubCat: any;
    setSelectedSubCat: any;
    gameList: any;
    setGameList: any;
    allGamesProvider: any;
}

const HomeMobileMidMenu = ({ selectedSubCat, setSelectedSubCat, gameList, setGameList, allGamesProvider }: Props) => {
    const navigate = useNavigate();
    const { authState } = useAuth();
    const { setLoading } = useSpinner();
    const [getGameUrl, { data: gameData, loading: gameLoading, error: gameError }] = useMutation(GET_GAME_URL,
        { fetchPolicy: "no-cache" });
    const [loginModalVIsible, setLoginModalVisible] = useState(false);
    const [popupWindow, setPopupWindow] = useState<Window>();
    const [defaultSubKey, setDefaultSubKey] = useState('sports');
    const [selectedGame, setSelectedGame] = useState<any>();
    const { setErrorNotification } = useNotification();
    const intl = useIntl();
    const { layoutState } = useLayout();
    const [vpnDialogVisible, setVpnDialogVisible] = useState(false);

    useEffect(() => {
        setLoading(gameLoading); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gameLoading])

    useEffect(() => {
        if (gameData && gameData.gameUrl) {
            if (gameData.gameUrl.status === 'SUCCESS' && gameData.gameUrl.url) {
                // if (popupWindow) {
                //     popupWindow.location.href = gameData.gameUrl.url;

                if (gameData.gameUrl.token) {
                    let hostName = window.location.hostname;
                    if (hostName.indexOf('localhost') >= 0 || hostName.indexOf('bo-ib8.net') >= 0)
                        hostName = 'bo-ib8.net';
                    else {
                        hostName = hostName.replace('www.', '');
                    }

                    setTokenCookie('s', gameData.gameUrl.token, hostName);
                }

                if (gameData.gameUrl.isLineBrowser) {
                    window.location.replace(gameData.gameUrl.url);
                } else {
                    if (popupWindow) {
                        popupWindow.location.href = gameData.gameUrl.url;
                        //window.open(gameData.gameUrl.url, "gameTab");
                    }
                }

                // window.open(gameData.gameUrl.url, "gameTab");

            } else {
                // if (popupWindow) {
                //     popupWindow.close(); // eslint-disable-next-line react-hooks/exhaustive-deps
                //     setErrorNotification(intl.formatMessage({ id: `ERROR_GENERAL` }));
                // }
            }
        }
    }, [gameData]);

    const onMenuRedirectClick = (providerCode: string, gameCode: string) => {
        if (!checkIsIOSLineApp()) {
            const promptWindow = window.open('', "_blank");
            if (promptWindow)
                setPopupWindow(promptWindow);
        }

        let hostName = window.location.hostname;
        if (hostName.indexOf('localhost') >= 0 || hostName.indexOf('bo-ib8.net') >= 0)
            hostName = 'bo-ib8.net';
        else {
            hostName = hostName.replace('www.', '');
        }

        getGameUrl({
            variables: {
                gameUrlInput: {
                    gameProviderCode: providerCode,
                    platform: 'mobile',
                    lang: intl.locale,
                    isFreeGame: 'false',
                    gameCode,
                    domainUrl: hostName
                }
            }
        });
    }

    const onMenuItemClick = (provider: string) => {
        navigate(provider);
    }

    const onGameClick = async (game: any) => {
        // if (authState.isLogin) {
        //     setSelectedGame(game);
        //     game.hasUrl ? setTransferModalVisible(true) : onMenuItemClick(game.url);
        // }
        // else {
        if (authState.isLogin){
            if (game.label === 'PRAGMATICPLAY') {
                const loc: string = await checkLocation();
                if (loc === "SG") {
                    setVpnDialogVisible(true);
                    return;
                }
            }
            game.hasUrl ? onMenuRedirectClick(game.url, game.gameCode) : onMenuItemClick(game.url);
        }
        else {
            game.hasUrl ? setLoginModalVisible(true) : onMenuItemClick(game.url);
        }
    }

    const onProviderChange = (key: string) => {
        setGameList(allGamesProvider?.filter((x: any) => x.gameType === key)[0]);
        setSelectedSubCat(key);
    };

    return (
        <>
            <div className={authState?.defaultCurrency === 'SGD' ?
                layoutState?.locale === 'en' ? "home-mobile-mid-menu-container-less-item" : "home-mobile-mid-menu-container-short-names" :
                "home-mobile-mid-menu-container"}>
                <MobileGameListTabHeader
                    selectedSubCat={selectedSubCat}
                    defaultSubKey={defaultSubKey}
                    onProviderChange={onProviderChange}
                    allGamesProvider={allGamesProvider} />
                <div className="home-mobile-game-list-container">
                    {gameList && gameList.games && gameList.games.length > 0 &&
                        <div className={`home-mobile-game-list-container-type-${gameList.mobileGameListType}`}>
                            {
                                gameList.games.map((game: any) => {
                                    return ((authState?.isLogin && game?.currency.filter((x: any) => x === authState.userProfile?.currency).length > 0) ||
                                        (!authState.isLogin && game?.currency.filter((x: any) => x === authState?.defaultCurrency).length > 0)) &&
                                        <>{
                                            gameList.mobileGameListType === 1 ?
                                                <MobileGameListRow game={game} onGameClick={onGameClick} /> :
                                                <MobileGameListIcons game={game} onGameClick={onGameClick} />
                                        }
                                        </>
                                })
                            }
                        </div>
                    }
                </div>
            </div>
            <HeaderLoginMobile modalVisible={{ visible: loginModalVIsible, setVisible: setLoginModalVisible }} setLoading={setLoading} />
            <VpnDialog vpnDialogVisible={vpnDialogVisible} setVpnDialogVisible={setVpnDialogVisible} />
            {/* <MobileTransferModal providerId={selectedGame?.providerId} game={selectedGame} onMenuRedirectClick={onMenuRedirectClick} modalVisible={{ visible: transferModalVisible, setVisible: setTransferModalVisible }} /> */}
        </>
    );
};

export default HomeMobileMidMenu;
