
import "./HeaderMobile.less";

import Logo from '../../../assets/images/logo.svg';
import { Button, Collapse, Dropdown, Menu } from "antd";
import { FormattedMessage } from "react-intl";
import { MenuOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import HeaderMobileDrawer from "./HeaderDrawer";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderLoginMobile from "./HeaderLoginMobile";
import useSpinner from "../../../hooks/layout/useSpinner";
import { SET_TOTAL_WALLET_BALANCE, useAuth } from "../../../hooks/auth/AuthContext";
import { AiOutlineArrowLeft, AiOutlineMail, AiOutlineMinus, AiOutlinePlus, AiOutlinePlusCircle } from "react-icons/ai";
import NumberFormat from "react-number-format";
import { transferWalletList } from '../../../pages/dashboard/dashboard-bottom/components/transfer/TransferWalletLists';
import topMenuReload from "../../../assets/images/icon-rotate-new.svg";
import topMenuWallet from "../../../assets/images/icon-wallet-new.svg";
import { useLazyQuery } from "@apollo/client";
import { GET_ALL_WALLET_BALANCE } from "../../../pages/dashboard/dashboard-wallets/DashboardWallets.gql";

const { Panel } = Collapse;

interface Props {
    headerType: string;
}

const HeaderMobile = ({ headerType }: Props) => {
    const { setLoading } = useSpinner();
    const location = useLocation();
    const { authState, authDispatch } = useAuth();
    const navigate = useNavigate();
    const [totalBalance, setTotalBalance] = useState<number>(0);
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const [loginModalVIsible, setLoginModalVisible] = useState(false);
    const [walletDropdownClassName, setWalletDropdownClassName] = useState('dashboard-tansfer-wallet-menu');
    const [walletReloadClassName, setWalletReloadClassName] = useState('wallet-reload');
    const [getWalletBalance, { data, loading }] = useLazyQuery(GET_ALL_WALLET_BALANCE);

    useEffect(() => {
        if (loading)
            setWalletReloadClassName('wallet-reload-rotate');
        else
            setWalletReloadClassName('wallet-reload');
    }, [loading])

    useEffect(() => {
        if (data && data.walletBalance) {
            authDispatch({ type: SET_TOTAL_WALLET_BALANCE, payload: +data.walletBalance?.totalBalance.toFixed(2) });
        }
    }, [data]);

    const showDrawer = () => {
        setIsDrawerVisible(true);
    };

    const register = () => {
        navigate('register');
    }

    const navHome = () => {
        navigate('/');
    }

    const onLoginClick = () => {
        setLoginModalVisible(true);
    }

    const renderWalletMenu = (walletList: any[]) => {
        return <Menu
            className={walletDropdownClassName}
            mode='vertical'>
            <Collapse
                accordion
                expandIcon={({ isActive }) => isActive ? <AiOutlineMinus /> : <AiOutlinePlus />}
                expandIconPosition="right"
                bordered={false}>
                {authState?.walletCategory && authState?.walletCategory.length > 0 &&
                    authState?.walletCategory.map((walletCategory: { seq: number; labelName: string; productName: string; bal: number }) => {
                        return <Panel key={walletCategory.productName} header={
                            <>
                                <div className='transfer-collapse-panel-header'>
                                    <div>{walletCategory.productName}</div>
                                    <div>
                                        <NumberFormat value={walletCategory.bal} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} />
                                    </div>
                                </div>
                            </>}>
                            {authState?.wallets && authState?.wallets?.filter((x: { userWalletType: { displayProductId: string; id: string }; }) => walletCategory.productName === 'MAIN' ? x.userWalletType?.id === 'MAIN' || x.userWalletType?.id === 'BONUS' : x.userWalletType?.id === walletCategory.productName) &&
                                authState?.wallets?.filter((x: { userWalletType: { displayProductId: string; id: string }; }) => walletCategory.productName === 'MAIN' ? x.userWalletType?.id === 'MAIN' || x.userWalletType?.id === 'BONUS' : x.userWalletType?.displayProductId === walletCategory.productName).map((wallet: { userWalletType: { displayName: string; }; id: string; name: string; balance: number; }) => {
                                    return <div key={wallet.id ?? 'default'} className='transfer-child-menu' onClick={() => { menuItemClick(wallet.userWalletType.displayName, wallet.id, wallet.name) }}>
                                        <div className='transfer-child-menu-name'>{wallet.userWalletType.displayName} </div>
                                        <div className='transfer-child-menu-right-elements'>
                                            <div className='transfer-child-menu-balance'>
                                                <NumberFormat value={wallet.balance} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} />
                                            </div>
                                            <div className='transfer-child-menu-icon'>
                                                <AiOutlinePlusCircle />
                                            </div>
                                        </div>
                                    </div>

                                })
                            }
                        </Panel>
                    })
                }
            </Collapse>
        </Menu>;
    }

    const menuItemClick = (name: string, value: string, type: string) => {
        setWalletDropdownClassName('dashboard-tansfer-wallet-menu');
    }

    const onInboxClick = () => {
        navigate('dashboard/profile/messaging');
    }

    const onWalletReloadClick = () => {
        getWalletBalance();
    }

    const onBackClick = () => {
        location.pathname.includes('banking') ?
            navigate('dashboard/banking') :
            navigate('dashboard/profile')
    }

    const onDepositClick = () => {
        navigate('dashboard/banking/deposit');
    }

    return (
        <>
            <div className={headerType === 'HOME' ? "header-mobile-container" : "header-mobile-title-only-container"}>
                {
                    headerType === 'HOME' &&
                    <img className="mobile-header-logo" src={Logo} alt="logo" onClick={navHome} />
                }
                <>
                    {headerType === 'HOME' ?
                        <div className="header-mobile-right">
                            {authState.isLogin ?
                                <>
                                    <div className='login-header-elements'>
                                        <div className='login-wallet-btn-amt'>
                                            <div className="wallet-amt-currency">{authState.userProfile?.currency}</div>
                                            <div className="wallet-amt-amt">
                                                <NumberFormat displayType="text" value={authState?.totalBalance ?
                                                    authState?.totalBalance.toFixed(2) : totalBalance.toFixed(2)} thousandSeparator={true} />
                                            </div>
                                        </div>
                                        <img className={walletReloadClassName} src={topMenuReload} alt='reload' onClick={onWalletReloadClick} />
                                        <img className="wallet-icon" src={topMenuWallet} alt='reload' onClick={onDepositClick} />
                                    </div>
                                </> :
                                <>

                                </>
                            }
                            <div className="mobile-menu-btn" onClick={showDrawer}>
                                <MenuOutlined />
                            </div>
                        </div> :
                        <div className="header-title-only">
                            {(location.pathname.includes('banking/') || location.pathname.includes('profile/')) &&
                                <div className="header-back" onClick={onBackClick}><AiOutlineArrowLeft /></div>
                            }
                            <div className="header-title"><FormattedMessage id={headerType} /></div>
                            <div className="mobile-menu-btn" onClick={showDrawer}>
                                <MenuOutlined />
                            </div>
                        </div>
                    }
                </>
            </div>
            <HeaderMobileDrawer isDrawerVisible={isDrawerVisible} setIsDrawerVisible={setIsDrawerVisible} />
        </>

    );
};

export default HeaderMobile;
