import { Button } from "antd";
import moment from "moment";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { FormattedMessage } from "react-intl";
import { setGeneralCookie } from "../../../shared/helpers/set-general-cookie.helper";
import { VerificationType } from "../../helpers/general-helpers";

interface Props {
    type: VerificationType;
    hasError: boolean;
    isSuccess?: boolean;
    requestStatus: () => void;
    isPinMatched: boolean;
    requestOTPCodeLoading?: boolean;
    isRequestCode: boolean;
    setIsRequestCode: Dispatch<SetStateAction<boolean>>;
}
const CodeRequestButton = ({ type, requestStatus, hasError, isSuccess = false, isPinMatched = false, requestOTPCodeLoading = false, isRequestCode = false,
    setIsRequestCode }: Props) => {
    // eslint-disable-next-line
    const [cookies, setCookie, removeCookie] = useCookies();
    const [cooldown, setCooldown] = useState<number>(0);
    const [cooldownTimer, setCooldownTimer] = useState<number>();
    const [countdownStart, setCountdownStart] = useState<number>(179);

    useEffect(() => {
        if (hasError === false) {
            const cooldownFromCookie = cookies[`c-cooldown-${type.toString()}`];
            const cooldownTIme = cookies[`c-cooldown-start-${type.toString()}`] ? +cookies[`c-cooldown-start-${type.toString()}`] : 179;

            setCountdownStart(+cooldownTIme);

            if (cooldownFromCookie) {
                const timePassed = moment().unix() - (+cooldownFromCookie);
                //due to latency, reduce 1 sec
                const remainingSeconds = timePassed > +cooldownTIme ? 0 : (+cooldownTIme - timePassed);
                setCooldown(remainingSeconds);
                initiateTimer(remainingSeconds);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const clearTimer = () => {
            removeCookie(`c-cooldown-${type.toString()}`);
            if (cooldownTimer) {
                clearInterval(cooldownTimer);
            }
        }
        return () => clearTimer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cooldownTimer])

    useEffect(() => {
        if (hasError || isSuccess) {
            removeCookie(`c-cooldown-${type.toString()}`);
            setCooldown(0);
            if (cooldownTimer) {
                clearInterval(cooldownTimer);
            }
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasError || isSuccess]);

    useEffect(() => {
        if (isRequestCode && cooldown === 0) {
            //1 minute cooldown
            setGeneralCookie(`c-cooldown-${type.toString()}`, moment().unix().toString(), countdownStart);
            setCooldown(countdownStart);
            if (cooldownTimer) {
                clearInterval(cooldownTimer);
            }
            initiateTimer(countdownStart);
            setIsRequestCode(false);
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRequestCode]);

    const initiateTimer = (amount: number) => {
        const cooldownInterval: number = window.setInterval(() => {
            if (amount > 0) {
                setCooldown(amount--);
            } else {
                setCooldown(0);
                clearInterval(cooldownInterval);
            }
        }, 1000);
        setCooldownTimer(cooldownInterval);
    }

    const requestCode = () => {
        if (cooldown === 0) {
            requestStatus();
        }
    }


    return (
        <Button className='otp-request-btn' disabled={cooldown > 0 || !isPinMatched} onClick={requestCode} loading={requestOTPCodeLoading}>
            {(!cooldown || cooldown === 0) ? <FormattedMessage id="FORGOT_PASSWORD_OTP_REQUEST" />
                : (cooldown > 1 ? <FormattedMessage id="OTP_BTN_COOL_DOWNS" values={{ cooldown }} />
                    : <FormattedMessage id="OTP_BTN_COOL_DOWN" values={{ cooldown }} />)}
        </Button>)

}

export default CodeRequestButton;