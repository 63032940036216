
import "../AuthMobile.less";
import { Alert, Button, DatePicker, Form, Input } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { useEffect, useState } from 'react';
import passwordIcon from '../../../../assets/images/icon-form-password.png';
import phoneIcon from '../../../../assets/images/icon-form-contact.png';
import dobIcon from '../../../../assets/images/icon-form-calendar.png';
import emailIcon from '../../../../assets/images/icon-form-mail.png';
import userNameIcon from '../../../../assets/images/icon-form-username.png';
import RegisterDisclaimer from "./RegisterMobileDisclaimer";
import PhoneInputFloatingLabel from "../../../../shared/components/phone-input/Phone-Input-floating-label";
import moment from "moment";
import { SET_LOGIN_STATUS, useAuth } from "../../../../hooks/auth/AuthContext";
import { useMutation } from "@apollo/client";
import { setTokenCookie } from "../../../../shared/helpers/set-token-cookie.helper";
import { USER_SIGNUP } from "../../../auth/auth.gql";
import { getCountryIdByCurrency, getDialCodeByCurrency } from "../../../../shared/helpers/general-helpers";
import useNotification from "../../../../hooks/layout/useNotification";
import { useLocation } from "react-router";
import useSpinner from "../../../../hooks/layout/useSpinner";
import affReferralIcon from '../../../../assets/images/icon-form-affiliate.png';
import { encode } from "base-64";
import Captcha from "../../../../shared/components/captcha/Captcha";
import { CAPTCHA_CODE } from "../../../../shared/components/captcha/Captcha.gql";
import cuid from "@paralleldrive/cuid2";
interface Props {
    step: number;
    next: () => void;
}

const RegisterMobileStep2 = ({ next, step }: Props) => {
    const [form2] = Form.useForm();
    const { authState, authDispatch } = useAuth();
    const intl = useIntl();
    const [emailFocus, setEmailFocus] = useState(false);
    const [phoneFocus, setPhoneFocus] = useState(false);
    const [fullNameFocus, setFullNameFocus] = useState(false);
    const { setSuccessNotification, setErrorNotification } = useNotification();
    const [dobFocus, setDobFocus] = useState(false);
    const [emailInput, setEmailInput] = useState("");
    const [phoneInput, setPhoneInput] = useState("");
    const [fullNameInput, setFullNameInput] = useState("");
    const [dobInput, setDobInput] = useState("");
    const [phone, setPhone] = useState<string>();
    const [dialCode, setDialCode] = useState(getDialCodeByCurrency(authState?.defaultCurrency));
    const [signUp, { data, loading, error }] = useMutation(USER_SIGNUP, { fetchPolicy: "no-cache", errorPolicy: "all" });
    const location = useLocation();
    const { setLoading } = useSpinner();
    const query = new URLSearchParams(location.search);
    const [userNameFocus, setUserNameFocus] = useState(false);
    const [passwordFocus, setPasswordFocus] = useState(false);
    const [confirmPasswordFocus, setConfirmPasswordFocus] = useState(false);
    const [affReferralFocus, setAffReferralFocus] = useState(false);
    const [userNameInput, setUserNameInput] = useState("");
    const [passwordInput, setPasswordInput] = useState("");
    const [confirmPasswordInput, setConfirmPasswordInput] = useState("");
    const [affReferralInput, setAffReferralInput] = useState(query.get('refId') ?? "");
    const [authError, setError] = useState<string>();
    const [isDisableRefId, setIsDisableRefId] = useState(query.get('refId') ? true : false);
    const [getCaptchaCode, { data: getCaptchaCodeData }] = useMutation(CAPTCHA_CODE, { fetchPolicy: "no-cache", errorPolicy: "all" });
    const [captcha, setCaptcha] = useState<string>('8888');
    const [sessionId, setSessionId] = useState<string>('sessionId');

    useEffect(() => {
        generateCaptcha()
    }, [])

    useEffect(() => {
        if (getCaptchaCodeData && getCaptchaCodeData.captchaCode) {
            if (getCaptchaCodeData.captchaCode.status === 'SUCCESS') {
                setCaptcha(getCaptchaCodeData.captchaCode.a);
            } else {
                setErrorNotification(intl.formatMessage({ id: 'GENERAL_ERROR' }));
            }
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getCaptchaCodeData]);


    useEffect(() => {
        setDialCode(getDialCodeByCurrency(authState?.defaultCurrency))
    }, [authState?.defaultCurrency])

    useEffect(() => {
        if (data && data.userSignUp) {
            if (data.userSignUp.status === 'SUCCESS') {
                const { a, r, sid } = data.userSignUp;
                setTokenCookie(process.env.REACT_APP_ACCESS_TOKEN || 'gmf-at', a);
                setTokenCookie(process.env.REACT_APP_REFRESH_TOKEN || 'gmf-rt', r);
                setTokenCookie('gmf-sid', sid);

                authDispatch({ type: SET_LOGIN_STATUS, payload: true });
                next();
            } else if (data.userSignUp.status === 'CODE_NOT_MATCH') {
                setErrorNotification(intl.formatMessage({ id: 'INVALID_CAPTCHA' }));
            } else if (data.userSignUp.status === 'AGE_LIMIT') {
                setErrorNotification(intl.formatMessage({ id: 'AGE_LIMIT' }));
            } else if (data.userSignUp.status === 'PHONE_EXISTED') {
                setErrorNotification(intl.formatMessage({ id: 'ERROR_PHONE_EXISTED' }));
            } else if (data.userSignUp.status === 'COUNTRY_REFERRAL_INVALID') {
                setErrorNotification(intl.formatMessage({ id: 'ERROR_COUNTRY_REFERRAL_INVALID' }));
            } else if (data.userSignUp.status === 'ACCOUNT_EXISTED') {
                setErrorNotification(intl.formatMessage({ id: 'ERROR_USER_EXISTS' }));
            } else {
                next();
            }
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (error) {
            next();
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    const onFinish = (fieldsValue: any) => {

        form2.setFields([
            {
                name: "dob",
                errors: [""],
            }
        ]);
        if (dobInput === "") {
            form2.setFields([
                {
                    name: "dob",
                    errors: [intl.formatMessage({ id: 'AUTH_REGISTER_FORM_DOB_REQUIRED' })],
                }
            ]);
            return;
        }

        const variables: any = {
            registerInput: {
                username: fieldsValue.username ?? '',
                password: encode(fieldsValue.password) ?? '',
                affiliate: affReferralInput ?? '',
                email: fieldsValue.email ?? '',
                phoneNumber: phone ?? '',
                dob: new Date(moment(dobInput).startOf('day').toString()),
                fullName: fieldsValue.fullname ?? '',
                dialCode: dialCode,
                url: window.location.hostname,
                authCode: fieldsValue.captcha,
                sessionId,
            }
        };
        signUp({ variables });
    };

    const onValuesChange = ({ phone }: any) => {
        if (phone) {
            setPhone(phone);
        }
    }

    const generateCaptcha = () => {
        const sessionId: any = cuid.createId();
        setSessionId(sessionId);
        getCaptchaCode({
            variables: {
                userName: sessionId
            }
        });
    }

    const emailLabelClass = emailFocus || (emailInput && emailInput.length !== 0) ? "m-label m-label-float" : "m-label";
    const emailInputClass = emailFocus || (emailInput && emailInput.length !== 0) ? "m-input-underline" : "";

    const fullNameLabelClass = fullNameFocus || (fullNameInput && fullNameInput.length !== 0) ? "m-label m-label-float" : "m-label";
    const fullNameInputClass = fullNameFocus || (fullNameInput && fullNameInput.length !== 0) ? "m-input-underline" : "";

    const dobLabelClass = dobFocus || (dobInput && dobInput.length !== 0) ? "m-label m-label-float m-dob-label" : "m-label m-dob-label";
    const dobInputClass = dobFocus || (dobInput && dobInput.length !== 0) ? "m-input-underline" : "";

    const userNameLabelClass = userNameFocus || (userNameInput && userNameInput.length !== 0) ? "m-label m-label-float" : "m-label";
    const userNameInputClass = userNameFocus || (userNameInput && userNameInput.length !== 0) ? "m-input-underline" : "";

    const passwordLabelClass = passwordFocus || (passwordInput && passwordInput.length !== 0) ? "m-label m-label-float" : "m-label";
    const passwordInputClass = passwordFocus || (passwordInput && passwordInput.length !== 0) ? "m-input-underline" : "";

    const confirmPasswordLabelClass = confirmPasswordFocus || (confirmPasswordInput && confirmPasswordInput.length !== 0) ? "m-label m-label-float" : "m-label";
    const confirmPasswordInputClass = confirmPasswordFocus || (confirmPasswordInput && confirmPasswordInput.length !== 0) ? "m-input-underline" : "";

    const affReferralLabelClass = affReferralFocus || (affReferralInput && affReferralInput.length !== 0) ? "m-label m-label-float" : "m-label";
    const affReferralInputClass = affReferralFocus || (affReferralInput && affReferralInput.length !== 0) ? "m-input-underline" : "";

    return (
        <>
            <div className="m-register-form-title"><FormattedMessage id='AUTH_REGISTER_STEP2' /></div>
            <Form
                form={form2}
                layout="vertical"
                onValuesChange={onValuesChange}
                onFinish={onFinish}
            >
                {authError && <Alert
                    className="m-auth-error"
                    message={<FormattedMessage id={authError} />}
                    type="error"
                    showIcon
                />}
                <div className="m-register-form-item">
                    <img src={userNameIcon} alt="username" className="m-form-username-icon" />
                    <Form.Item
                        name="username"
                        rules={[
                            { required: true, message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_USERNAME_REQUIRED' }) },
                            {
                                pattern: /^[A-Za-z][A-Za-z0-9]{3,14}$/,
                                message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_USERNAME_INVALID' })
                            }]}>
                        <div
                            className="m-float-label"
                            onBlur={() => setUserNameFocus(false)}
                            onFocus={() => setUserNameFocus(true)}
                        >
                            <Input maxLength={15} value={userNameInput} className="m-input" onChange={e => setUserNameInput(e.target.value)} />
                            <label className={userNameLabelClass}>{intl.formatMessage({ id: 'AUTH_REGISTER_FORM_USERNAME' })}</label>
                            <div className={userNameInputClass}></div>
                        </div>
                    </Form.Item>
                </div>
                <div className="m-register-form-item">
                    <img src={passwordIcon} alt="password" className="m-form-password-icon" />
                    <Form.Item
                        name="password"
                        rules={[
                            { required: true, message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_PASSWORD_REQUIRED' }) },
                            {
                                pattern: /[A-Za-z0-9]{6,19}$/,
                                message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_PASSWORD_INVALID' })
                            }]}>
                        <div
                            className="m-float-label"
                            onBlur={() => setPasswordFocus(false)}
                            onFocus={() => setPasswordFocus(true)}
                        >
                            <Input.Password maxLength={20} className="m-input" value={passwordInput} onChange={e => setPasswordInput(e.target.value)} />
                            <label className={passwordLabelClass}>{intl.formatMessage({ id: 'AUTH_REGISTER_FORM_PASSWORD' })}</label>
                            <div className={passwordInputClass}></div>
                        </div>
                    </Form.Item>
                </div>
                <div className="m-register-form-item">
                    <img src={passwordIcon} alt="password" className="m-form-password-icon" />
                    <Form.Item
                        name="confirmpassword"
                        dependencies={['password']}
                        rules={[{ required: true, message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_PASSWORD_REQUIRED' }) },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error(intl.formatMessage({ id: 'AUTH_REGISTER_FORM_PASSWORD_NOT_MATCHED' })));
                            },
                        })]}>
                        <div
                            className="m-float-label"
                            onBlur={() => setConfirmPasswordFocus(false)}
                            onFocus={() => setConfirmPasswordFocus(true)}
                        >
                            <Input.Password className="m-input" value={confirmPasswordInput} onChange={e => setConfirmPasswordInput(e.target.value)} />
                            <label className={confirmPasswordLabelClass}>{intl.formatMessage({ id: 'AUTH_REGISTER_FORM_CONFIRM_PASSWORD' })}</label>
                            <div className={confirmPasswordInputClass}></div>
                        </div>
                    </Form.Item>
                </div>
                <div className="m-register-form-item">
                    <img src={emailIcon} alt="email" className="m-form-email-icon" />
                    <Form.Item
                        name="email"
                        rules={[
                            { required: true, message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_EMAIL_REQUIRED' }) },
                            { min: 5, message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_EMAIL_INVALID' }) }]}>
                        <div
                            className="m-float-label"
                            onBlur={() => setEmailFocus(false)}
                            onFocus={() => setEmailFocus(true)}
                        >
                            <Input className="m-input" value={emailInput} onChange={e => setEmailInput(e.target.value)} />
                            <label className={emailLabelClass}>{intl.formatMessage({ id: 'AUTH_REGISTER_FORM_EMAIL' })}</label>
                            <div className={emailInputClass}></div>
                        </div>
                    </Form.Item>
                </div>
                <div className="m-register-form-item">
                    <img src={phoneIcon} alt="phone" className="m-form-password-icon" />
                    <Form.Item
                        name="phone"
                        rules={[
                            { required: true, message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_PHONE_REQUIRED' }) },
                            { min: 3, message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_PHONE_REQUIRED' }) },
                            { min: 8, message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_PHONE_INVALID' }) },
                            { max: 12, message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_PHONE_INVALID' }) }]}>
                        <PhoneInputFloatingLabel selectedCountryId={getCountryIdByCurrency(authState?.defaultCurrency)} phoneFocus={phoneFocus} setPhoneFocus={setPhoneFocus} setDialCode={setDialCode} selectedDialCode={dialCode} />
                    </Form.Item>
                </div>
                <div className="register-form-item">
                    <div className="register-name-note"><FormattedMessage id='REG_PHONE_FORMAT_INFO' /></div>
                </div>
                <div className="m-register-form-item">
                    <img src={userNameIcon} alt="username" className="m-form-username-icon" />
                    <Form.Item
                        name="fullname"
                        rules={[
                            { required: true, message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_FULLNAME_REQUIRED' }) },
                            { min: 5, message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_FULLNAME_INVALID' }) }]}>
                        <div
                            className="m-float-label"
                            onBlur={() => setFullNameFocus(false)}
                            onFocus={() => setFullNameFocus(true)}
                        >
                            <Input className="m-input" value={fullNameInput} onChange={e => setFullNameInput(e.target.value)} />
                            <label className={fullNameLabelClass}>{intl.formatMessage({ id: 'AUTH_REGISTER_FORM_FULLNAME' })}</label>
                            <div className={fullNameInputClass}></div>
                        </div>
                    </Form.Item>
                </div>
                <div className="m-register-form-item">
                    <div className="m-register-name-note"><FormattedMessage id='AUTH_REGISTER_NAME_NOTE' /></div>
                </div>
                <div className="m-register-form-item">
                    <img src={dobIcon} alt="date of birth" className="m-form-calendar-icon" />
                    <div
                        className="m-float-label m-dob-float-label"
                        onBlur={() => setDobFocus(false)}
                        onFocus={() => setDobFocus(true)}
                    >
                        <Form.Item
                            name="dob">
                            <div className="m-float-label">
                                <DatePicker
                                    className="m-input"
                                    onChange={e => setDobInput(e ? e.toString() : '')}
                                    disabledDate={(current) => {
                                        return moment().add(-18, 'years') < current ||
                                            moment() <= current;
                                    }}
                                />
                                <label className={dobLabelClass}>{intl.formatMessage({ id: 'AUTH_REGISTER_FORM_DOB' })}</label>
                                <div className={`${dobInputClass} m-dob-input`}></div>
                            </div>

                        </Form.Item>

                    </div>
                </div>
                <div className="m-register-form-item">
                    <img src={affReferralIcon} alt="affiliate" className="m-form-aff-icon" />
                    <Form.Item
                        name="affiliate"
                        rules={[
                            { min: 5, message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_AFF_REFERRAL_INVALID' }) }]}>
                        <div
                            className="m-float-label"
                            onBlur={() => setAffReferralFocus(false)}
                            onFocus={() => setAffReferralFocus(true)}
                        >
                            <Input disabled={isDisableRefId} className="m-input" value={affReferralInput}
                                onChange={e => setAffReferralInput(e.target.value)} />
                            <label className={affReferralLabelClass}>{intl.formatMessage({ id: 'AUTH_REGISTER_FORM_AFF_REFERRAL' })}</label>
                            <div className={affReferralInputClass}></div>
                        </div>
                    </Form.Item>
                </div>
                <Captcha value={captcha} refresh={generateCaptcha} />
                <RegisterDisclaimer />
                <Form.Item className="m-form-button">
                    <Button block type="primary" htmlType="submit" className="m-register-next-btn" loading={loading}>
                        <FormattedMessage id={"AUTH_REGISTER_TNC_BTN_TXT"} />
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
};

export default RegisterMobileStep2;
