import React from "react";
import NoMatch from "./NoMatch";

export interface RouteInfo {
	path: string;
	component: any;
	routes?: RouteInfo[];
	lazy?: boolean;
	protected?: boolean;
}

const ReactLazyPreload = (importStatement: any) => {
	const Component: any = React.lazy(importStatement);
	Component.preload = importStatement;
	return Component;
};

const Register = ReactLazyPreload(() => import("./pages/auth/Register"));
Register.preload();

const AboutUs = ReactLazyPreload(() => import("./pages/footer-menu-pages/AboutUs"));
AboutUs.preload();

const InfoCentre = ReactLazyPreload(() => import("./pages/footer-menu-pages/InfoCentre"));
InfoCentre.preload();

const TNC = ReactLazyPreload(() => import("./pages/footer-menu-pages/TermsCondition"));
TNC.preload();

const ContactUs = ReactLazyPreload(() => import("./pages/footer-menu-pages/ContactUs"));
ContactUs.preload();

const PrivacyPolicy = ReactLazyPreload(() => import("./pages/footer-menu-pages/PrivacyPolicy"));
PrivacyPolicy.preload();

const Dashboard = ReactLazyPreload(() => import("./pages/dashboard/Dashboard"));
Dashboard.preload();

const Promo = ReactLazyPreload(() => import("./pages/promo/Promo"));
Promo.preload();

//const LocalGamePage = ReactLazyPreload(() => import("./pages/provider-game-page/GameList"));
//LocalGamePage.preload();

const SlotGamePage = ReactLazyPreload(() => import("./pages/provider-game-page/slots/SlotGamesPage"));
SlotGamePage.preload();

const FishGamePage = ReactLazyPreload(() => import("./pages/provider-game-page/fish/FishGamePage"));
FishGamePage.preload();

const LandingPage = ReactLazyPreload(() => import("./pages/provider-game-page/landing/GameLandingPage"));
LandingPage.preload();

const FAQPage = ReactLazyPreload(() => import("./pages/footer-menu-pages/FAQ"));
FAQPage.preload();

const LiveScore = ReactLazyPreload(()=> import("./pages/live-score/LiveScore"));
LiveScore.preload();

const CasinoLandingPage = ReactLazyPreload(() => import("./pages/provider-game-page/casino/CasinoLandingPage"));
CasinoLandingPage.preload();

const SportsLandingPage = ReactLazyPreload(() => import("./pages/provider-game-page/sports/SportsLandingPage"));
SportsLandingPage.preload();

const ESportsLandingPage = ReactLazyPreload(() => import("./pages/provider-game-page/esports/EsportsLandingPage"));
ESportsLandingPage.preload();

const HorseLandingPage = ReactLazyPreload(() => import("./pages/provider-game-page/horse/HorseLandingPage"));
HorseLandingPage.preload();

const VirtualLandingPage = ReactLazyPreload(() => import("./pages/provider-game-page/virtual/VirtualLandingPage"));
VirtualLandingPage.preload();

const LotteryLandingPage = ReactLazyPreload(() => import("./pages/provider-game-page/lottery/LotteryLandingPage"));
LotteryLandingPage.preload();

export const routes: RouteInfo[] = [
	{
		path: "/register",
		component: Register,
		protected: true,
	},
	{
		path: "/about-us",
		component: AboutUs,
		protected: true,
	},
	{
		path: "/info-centre",
		component: InfoCentre,
		protected: true,
	},
	{
		path: "/terms-and-conditions",
		component: TNC,
		protected: true,
	},
	{
		path: "/contact-us",
		component: ContactUs,
		protected: true,
	},
	{
		path: "/privacy-policy",
		component: PrivacyPolicy,
		protected: true,
	},
	{
		path: "/dashboard/:id",
		component: Dashboard,
		protected: true,
	},
	{
		path: "/dashboard",
		component: Dashboard,
		protected: true,
	},
	{
		path: "/promotions",
		component: Promo,
		protected: true,
	},
	{
		path: "/slots/:id",
		component: SlotGamePage,
		protected: true,
	},
	{
		path: "/fishing/:id",
		component: FishGamePage,
		protected: true,
	},
	{
		path: "/casino/:id",
		component: CasinoLandingPage,
		protected: true,
	},
	{
		path: "/sports/:id",
		component: SportsLandingPage,
		protected: true,
	},
	{
		path: "/esports/:id",
		component: ESportsLandingPage,
		protected: true,
	},
	{
		path: "/racing/:id",
		component: HorseLandingPage,
		protected: true,
	},
	{
		path: "/virtual/:id",
		component: VirtualLandingPage,
		protected: true,
	},
	{
		path: "/lottery/:id",
		component: LotteryLandingPage,
		protected: true,
	},
	{
		path: "/landing/:id",
		component: LandingPage,
		protected: true,
	},
	{
		path: "/faq",
		component: FAQPage,
		protected: true,
	},
	{
		path: "/live-score",
		component: LiveScore,
		protected: true,
	},
	{
		path: "*",
		component: NoMatch,
	},
];