import { useNavigate } from "react-router-dom";
import "./TopMenu.less";

import { FormattedMessage, useIntl } from "react-intl";
import { ReactChild, ReactFragment, ReactPortal, useEffect, useState } from "react";

import { useMutation } from "@apollo/client";
import { gamesProviderLeft, gamesProviderRight } from "./TopMenuList";
import useSpinner from "../../../hooks/layout/useSpinner";
import useNotification from "../../../hooks/layout/useNotification";
import { GET_GAME_URL } from "../../../pages/provider-game-page/Game.gql";
import { useAuth } from "../../../hooks/auth/AuthContext";
import LoginModal from "../../login_modal/LoginModal";
import { AiOutlineHome } from 'react-icons/ai';
import { checkIsGameAvailable, checkIsGameComingSoon, checkLocation, formatUrlCurrencyLang } from "../../helpers/general-helpers";
import TransferModal from "../../transfer-modal/TransferModal";
import { setTokenCookie } from "../../helpers/set-token-cookie.helper";
import { useLayout } from "../../../hooks/layout/LayoutContext";
import { forEach } from "lodash";
import VpnDialog from "../vpn-dialog/VpnDialog";


const TopMenu = () => {
    const navigate = useNavigate();
    const { authState } = useAuth();
    const { layoutState } = useLayout();
    const { setLoading } = useSpinner();
    const intl = useIntl();
    const { setErrorNotification } = useNotification();
    const [loginModalVisible, setLoginModalVisible] = useState(false);
    const [getGameUrl, { data: gameData, loading: gameLoading, error: gameError }] = useMutation(GET_GAME_URL,
        { fetchPolicy: "no-cache" });
    const [vpnDialogVisible, setVpnDialogVisible] = useState(false);
    let timeout: any;

    useEffect(() => {
        setLoading(gameLoading); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gameLoading])

    useEffect(() => {
        if (gameData && gameData.gameUrl) {
            if (gameData.gameUrl.status === 'SUCCESS' && gameData.gameUrl.url) {
                // if (popupWindow) {
                //     popupWindow.location.href = gameData.gameUrl.url;

                if (gameData.gameUrl.token) {
                    let hostName = window.location.hostname;
                    if (hostName.indexOf('localhost') >= 0 || hostName.indexOf('bo-ib8.net') >= 0)
                        hostName = 'bo-ib8.net';
                    else {
                        hostName = hostName.replace('www.', '');
                    }

                    setTokenCookie('s', gameData.gameUrl.token, hostName);
                }

                window.open(gameData.gameUrl.url, "gameTab");
                // }
            } else {
                // if (popupWindow) {
                //     popupWindow.close(); // eslint-disable-next-line react-hooks/exhaustive-deps
                // }
                setErrorNotification(intl.formatMessage({ id: `ERROR_GENERAL` }));
            }
        }
    }, [gameData]);

    useEffect(() => {
        if (gameError) {
            // if (popupWindow) {
            //     popupWindow.close();
            // }

            if (gameError.message?.indexOf("INVALID_CODE") > -1) {
                setErrorNotification(intl.formatMessage({ id: `ERROR_INVALID_CODE` }));
            } else if (gameError.message?.indexOf('INVALID_USER') > -1) {
                setErrorNotification(intl.formatMessage({ id: `ERROR_INVALID_USER` }));
            } else {
                setErrorNotification(intl.formatMessage({ id: `ERROR_GENERAL` }));
            }
        }

    }, [gameError])

    const onMenuRedirectClick = (providerCode: string, gameCode: string) => {
        // const promptWindow = window.open('', "_blank");
        // if (promptWindow)
        //     setPopupWindow(promptWindow);

        let hostName = window.location.hostname;
        if (hostName.indexOf('localhost') >= 0 || hostName.indexOf('bo-ib8.net') >= 0)
            hostName = 'bo-ib8.net';
        else {
            hostName = hostName.replace('www.', '');
        }

        getGameUrl({
            variables: {
                gameUrlInput: {
                    gameProviderCode: providerCode,
                    platform: 'web',
                    lang: intl.locale,
                    isFreeGame: 'false',
                    gameCode,
                    domainUrl: hostName
                }
            }
        });
    }

    const onMenuItemClick = (provider: string) => {
        navigate(provider);
    }

    const _onMouseOver = (e: any) => {
        const _val = 'submenu_' + e.currentTarget.querySelector('.menu-item-label').innerHTML;
        const _menu = document.getElementsByClassName('menu-item');
        clearTimeout(timeout);

        for (var i = 0; i < _menu.length; i++) {
            _menu[i].querySelector('.submenu')?.classList.remove('ukopen');
        }

        document.getElementById(_val)?.classList.add('ukopen');
    }

    const _onMouseOut = (e: any) => {
        const _val = 'submenu_' + e.currentTarget.querySelector('.menu-item-label').innerHTML;

        timeout = setTimeout(function () {
            document.getElementById(_val)?.classList.remove('ukopen');
        }, 1000);
    }

    const onGameClick = async (game: any) => {
        if (authState.isLogin) {
            if (game.label === 'PRAGMATICPLAY') {
                const loc: string = await checkLocation();
                if (loc === "SG") {
                    setVpnDialogVisible(true);
                    return;
                }
            }
            game.hasUrl ? onMenuRedirectClick(game.url, game.gameCode) : onMenuItemClick(game.url);
        }
        else {
            game.hasUrl ? setLoginModalVisible(true) : onMenuItemClick(game.url);
        }
    }

    const onProductClick = (gameType: string, games: any) => {
        let game: any = {};
        let currency = authState?.isLogin ? authState?.userProfile?.currency ? authState?.userProfile?.currency : authState?.defaultCurrency : authState?.defaultCurrency

        forEach(games, (_game: any) => {
            if (_game.currency.filter((x: string) => x === currency).length > 0) {
                game = _game;
                return false;
            }
        })

        //if (authState.isLogin)
        if (gameType === 'slots' || gameType === 'fishing') {
            onMenuItemClick(game.url);
        } else {
            navigate(`${gameType === 'horseracing' ? 'racing' : gameType}/${game.urlLabel}`);
        }
        // else
        //     setLoginModalVisible(true);
    }

    const onAffiliateClick = () => {
        let url = '';
        let hostName = window.location.hostname;
        if (hostName.indexOf('localhost') >= 0 || hostName.indexOf('bo-ib8.net') >= 0)
            url = 'https://stg-ib8-affiliate.firebaseapp.com';
        else {
            hostName = hostName.replace('www.', '');
            url = `https://aff.${hostName}`;
        }
        window.open(url);
    }

    // const checkIsGameAvailable = (game: any) =>{
    //     return game.url && !(authState.maintenanceList.length > 0 && authState.maintenanceList.filter((x: string | undefined) => x === game.providerId).length > 0)
    // }

    const gameMenu = (gameList: any[]) => {
        return gameList.map(gp => (
            ((authState.isLogin && gp.currency.filter((x: any) => x === authState.userProfile?.currency).length > 0) ||
                (!authState.isLogin && gp.currency.filter((x: any) => x === authState?.defaultCurrency).length > 0)) &&
            <li key={gp.gameType} className="menu-item">
                <div key={gp.gameType + '_label'} className="menu-item-component" onClick={() => { onProductClick(gp.gameType, gp.games) }} onMouseOver={window.innerWidth > 820 ? _onMouseOver : () => { }} onMouseOut={window.innerWidth > 820 ? _onMouseOut : () => { }}>
                    <div className="menu-item-label"><FormattedMessage id={gp.label} /></div>
                    {/* <img className="menu-chevron-down" src={ChevronDown} alt="home" /> */}
                </div>

                {gp && gp?.games && gp.games.length > 0 &&
                    <ul className="submenu" id={'submenu_' + intl.formatMessage({ id: gp.label })}>
                        <div className={(gp.gameType !== 'fishing' && gp.gameType !== 'slots') ? "submenu-wrapper-wide" : "submenu-wrapper"}>
                            {gp.games.map((game: any) => (
                                ((authState?.isLogin && game?.currency.filter((x: any) => x === layoutState.currency).length > 0) ||
                                    (!authState.isLogin && game?.currency.filter((x: any) => x === authState?.defaultCurrency).length > 0)) &&
                                <li key={game?.id} className="provider-wrapper" onClick={checkIsGameAvailable(game, authState?.maintenanceList ?? []) ? () => { onGameClick(game) } : () => { }}>
                                    <div className={
                                        checkIsGameAvailable(game, authState?.maintenanceList ?? [])
                                            ? game?.isNew ? 'new-txt' : "coming-soon-txt-inactive" :
                                            checkIsGameComingSoon(game) ? 'coming-soon-txt' :
                                                "maintenance-txt"} >
                                        <FormattedMessage id={checkIsGameAvailable(game, authState?.maintenanceList ?? []) ? game?.isNew ? 'NEW_PROVIDER' : 'NEW_PROVIDER' : checkIsGameComingSoon(game) ? 'COMING_SOON' : 'MAINTENANCE'} />
                                    </div>
                                    <a className={checkIsGameAvailable(game, authState?.maintenanceList ?? []) ? "provider-link" : "provider-link-inactive"}>
                                        <div className={
                                            checkIsGameAvailable(game, authState?.maintenanceList ?? [])
                                                ? "img-wrapper" : "img-wrapper-inactive"}>
                                            {game.image && <img className="game-img" src={game.image} alt={game.id}></img>}
                                            <div className="circle"></div>
                                        </div>
                                        {game.logo &&
                                            <img className={
                                                checkIsGameAvailable(game, authState?.maintenanceList ?? [])
                                                    ? "game-logo" : "game-logo-inactive"}
                                                src={game.logo} alt={game.id}></img>}
                                        {/* <label>{game.label}</label> */}
                                    </a>
                                </li>
                            ))}
                        </div>
                    </ul>
                }
            </li>
        ))
    }

    return (
        <>
            <div className="nav-container">
                <div className="nav-wrapper">
                    <ul className="menu">
                        <li key={'home'} className="menu-item-icon" onClick={() => { navigate('/') }}>
                            <a key={'home_label'} className="menu-home" href="#">
                                <AiOutlineHome />
                                {/* <img className="menu-home" src={HomeIcon} alt="home" /> */}
                            </a>
                        </li>
                        {/* <li className="menu-item-icon">
                            <a className="menu-phone" href="#">
                                <IoIosPhonePortrait />
                                 <img className="menu-phone" src={PhoneIcon} alt="mobile" /> 
                            </a>
                        </li> */}
                        {gamesProviderLeft && gamesProviderLeft.length > 0 && gameMenu(gamesProviderLeft)}
                        {/* <li className="menu-item logo-wrapper">
                            <img className="logo-wrap" src={Logo} alt="" />
                        </li> */}
                        {gamesProviderRight && gamesProviderRight.length > 0 && gameMenu(gamesProviderRight)}
                        <li key={'promo'} className="menu-item">
                            <div key={'promo_label'} className="menu-item-component">
                                <div className="menu-item-label" onClick={() => { navigate('promotions') }}><FormattedMessage id='MENU_PROMO' /></div>
                            </div>
                        </li>
                        <li key={'live-score'} className="menu-item">
                            <div key={'promo_label'} className="menu-item-component">
                                <div className="menu-item-label" onClick={() => { navigate('live-score') }}><FormattedMessage id='MENU_LIVE' /></div>
                            </div>
                        </li>
                        {/* {layoutState.currency === 'THB' && */}
                        <li key={'aff'} className="menu-item">
                            <div key={'aff_label'} className="menu-item-component">
                                <div className="menu-item-label" onClick={() => { onAffiliateClick(); }} ><FormattedMessage id='MENU_AFFILIATE' /></div>
                            </div>
                        </li>
                        {/* } */}
                    </ul>
                </div>
            </div>
            <LoginModal modalVisible={{ visible: loginModalVisible, setVisible: setLoginModalVisible }} />
            <VpnDialog vpnDialogVisible={vpnDialogVisible} setVpnDialogVisible={setVpnDialogVisible} />
            {/* <TransferModal providerId={selectedGame?.providerId} game={selectedGame} onMenuRedirectClick={onMenuRedirectClick} modalVisible={{ visible: transferModalVisible, setVisible: setTransferModalVisible }} /> */}
        </>

    );
};

export default TopMenu;